<template>
  <div v-if="jdata">
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="11" class="my-auto">
          <h3 class="primary--text">
            {{ unor_name.toUpperCase() }}
          </h3>
          <h4 class="primary--text font-weight-light" v-if="jdata.namaJabatan">
            DETAIL JABATAN - {{ jdata.namaJabatan.toUpperCase() }}
          </h4>
        </v-col>

        <MEdit />

        <v-col cols="12" md="1" class="text-right my-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                @click="editItem()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }} Edit Data Jabatan
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import MEdit from "@/components/anjab/petajabatan/edit";

import SEdit from "@/store/anjab/petajabatan/edit";
import SView from "@/store/anjab/petajabatan/view";
import SInfojab from "@/store/anjab/petajabatan/infojab";

export default {
  components: {
    MEdit,
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),

    jdata: {},
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "anjab/id/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          SView.commit("refreshData", false);
          this.ldata = false;
          this.jdata = res.data.mapData[0];
          this.getInfojab();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getInfojab() {
      const url =
        process.env.VUE_APP_API + "infojab/getall/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          SInfojab.commit("VIHKTISAR", res.data.mapData.ihktisar);
          SInfojab.commit("VTUGAS", res.data.mapData.tugas);
          SInfojab.commit("VTANGGUNGJAWAB", res.data.mapData.tanggungjawab);
          SInfojab.commit("VWEWENANG", res.data.mapData.wewenang);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem() {
      SEdit.commit("TModal", true);
      SEdit.commit("VData", this.jdata);
    },
  },
};
</script>
