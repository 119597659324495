import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ihktisar: null,
        tugas: [],
        tanggungJawab: [],
        wewenang: []
    },

    mutations: {
        VIHKTISAR(state, value) {
            state.ihktisar = value
        },

        VTUGAS(state, value) {
            state.tugas = value
        },

        VTANGGUNGJAWAB(state, value) {
            state.tanggungJawab = value
        },

        VWEWENANG(state, value) {
            state.wewenang = value
        },
    }
})